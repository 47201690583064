type Props<C, I> = {
    cmp?: C;
    id?: I;
};

type Ret<C, I> = {
    'data-cmp'?: C;
    'data-testid'?: I;
    'data-cy'?: I;
};

/**
 * Util function to produce attributes data-cmp and data-testid/data-cy.
 *
 * @deprecated
 *
 * Deprecation: We no longer use cypress (data-cy) for integration tests.
 * For new pages/components, just use data-testid.
 */
export default function elId<C = string, I = string>({ cmp, id }: Props<C, I>): Ret<C, I> {
    const inTest = !!process.env.JEST_WORKER_ID;

    const dataCmp: Ret<C, I> = cmp ? { 'data-cmp': cmp } : {};

    const dataTest: Ret<C, I> = id ? { [inTest ? 'data-testid' : 'data-cy']: id } : {};

    return {
        ...dataCmp,
        ...dataTest,
    };
}
