/**
 * Like class ApiService, but for the backend/node services.
 */
import axios, { AxiosRequestConfig, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { getLogger } from '../getLogger';
import { GetLambdaServicesRoutes, GetServicesRoutes } from '../serviceRegistry';
import { AxOptions, AxRequestHeaders, ReqRes } from './api.types';

const logger = getLogger('api');

// Set up API call retries
axiosRetry(axios, {
    retries: 3,
    // retryDelay: axiosRetry.exponentialDelay  // <-- also available
    // Using dumb backoff for now - 333ms wait * 3 == 1 second before complete failure
    retryDelay: (retryCount) => retryCount * 333,
});

// Acquire from master service list and touchup to suit local code
const endpointMap = GetServicesRoutes()
    .filter((svc) => svc.type !== 'lambda')
    .map(({ route, local, env }) => ({
        match: route,
        local,
        env,
    })) as { match: string; local: string; env: string }[];

export async function get<T extends ReqRes>(ep: string, opts?: AxOptions): P<T[1]> {
    return request(ep, 'get', undefined, opts);
}

export async function post<T extends ReqRes>(ep: string, body?: T[0], opts?: AxOptions): P<T[1]> {
    return request(ep, 'post', body, opts);
}

export async function put<T extends ReqRes>(ep: string, body?: T[0], opts?: AxOptions): P<T[1]> {
    return request(ep, 'put', body, opts);
}

export async function del<T extends ReqRes>(ep: string, body?: T[0], opts?: AxOptions): P<T[1]> {
    return request(ep, 'delete', body, opts);
}

export async function patch<T extends ReqRes>(ep: string, body?: T[0], opts?: AxOptions): P<T[1]> {
    return request(ep, 'patch', body, opts);
}

/**
 * Convert "simplified" endpoint to absolute URL.
 *
 * eg: convert '/payroll/a/b/c' to either:
 *   - http://payroll.aps-services.local/a/b/c, or
 *   - http://localhost:4003/a/b/c
 */
function resolveEndpointUrl(endpointIn: string): { url: string; isDirect: boolean } {
    const serviceUrl = resolveServiceEndpointUrl(endpointIn);

    if (!serviceUrl) {
        const lambdaUrl = resolveLambdaEndpointUrl(endpointIn);

        if (!lambdaUrl) throw new Error(`Unable to resolve endpoint ${endpointIn}`);

        return lambdaUrl;
    }

    return serviceUrl;
}

function resolveLambdaEndpointUrl(endpointIn: string): { url: string; isDirect: boolean } | null {
    const mapItem = GetLambdaServicesRoutes().find(({ route: match }) =>
        endpointIn.startsWith(match),
    );

    if (!mapItem) return null;

    // TODO fixup in DLV-#### temp to unblock local dev while infra is being set up
    const baseUrl = `http://localhost:3000/api`;

    return {
        url: String(new URL(`${baseUrl}${endpointIn}`)),
        isDirect: false,
    };
}

/**
 * Convert "simplified" endpoint to absolute URL.
 *
 * eg: convert '/payroll/a/b/c' to either:
 *   - http://payroll.aps-services.local/a/b/c, or
 *   - http://localhost:4003/a/b/c
 *   @returns the absolute URL or null if no endpoint match is found
 */
function resolveServiceEndpointUrl(endpointIn: string): { url: string; isDirect: boolean } | null {
    const mapItem = endpointMap.find(({ match }) => endpointIn.startsWith(match));

    if (!mapItem) return null;

    // Strip the "service" prefix (ie: remove '/person/')
    const endpoint = endpointIn.replace(mapItem.match, '');
    // The prefix to prepend
    const baseUrl = process.env[mapItem.env] || `http://${mapItem.local}.aps-services.local`;

    return {
        url: String(new URL(`.${endpoint}`, `${baseUrl}/`)),
        isDirect: !!process.env[mapItem.env],
    };
}

const excludeErrorProps = ['config', 'request', 'response', 'isAxiosError', 'toJson'];

async function request<T>(path: string, method: Method, data?: unknown, options?: AxOptions): P<T> {
    const { url } = resolveEndpointUrl(path);

    // TODO: Contemplate if we need to include browser relative context to build a "cross-service" request.
    // If so, then change the usage to new ApiService(context).get(...)

    const headers: AxRequestHeaders = {
        ...options?.headers,
    };

    const config: AxiosRequestConfig = { method, url, headers, data, params: options?.params };

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        // Log the error (as much as possible) but allow the upstream caller to handle the error

        const logData = {};
        const logMsg = (err as Error).message;

        const req: AnyObject = { method, url, headers, data };
        const res: AnyObject = {} as Record<string, unknown>;

        /* istanbul ignore else */
        if (axios.isAxiosError(err)) {
            /* istanbul ignore else */
            if (err.response) {
                const { status, statusText, data: resData } = err.response;
                // Putting full faith in responder returning appError with correct shape
                const { appError } = resData;
                // TODO: DLV-4055 duplicate the check for appErrors here
                if (appError) {
                    throw resData.appError;
                }
                Object.assign(res, { status, statusText, data: resData });
            } else {
                // Clone the error object removing properties we don't want to log
                // Note: Using the destructuring method results in missing properties, so using Object.entries instead.
                Object.entries(err)
                    .filter(([key]) => !excludeErrorProps.includes(key))
                    .forEach(([k, v]) => {
                        res[k] = v;
                    });
            }
        }

        Object.assign(logData, { req, res });
        logger.error(logData, logMsg);
        throw err;
    }
}

/**
 * Axios Error Notes:
 * -------------------------------------------------------------------------------------------------
 *      err.message:    getaddrinfo ENOTFOUND slasxxhdot.org
 *      err.errno:     -3008,
 *      err.code:      'ENOTFOUND',
 *      err.syscall:   'getaddrinfo',
 *      err.hostname:  'slasxxhdot.org',
 *      err.config     props passed in plus some extras
 *      err.request:   a Writable
 *      err.request._currentRequest._header: string (sent to remote server)
 *      err.response:  undefined
 * -------------------------------------------------------------------------------------------------
 *      err.message:  Request failed with status code 404
 *      err.config    <Same as above>
 *      err.request:  <Same as above>
 *      err.response
 *      err.response.status         404
 *      err.response.statusText     Not Found
 *      err.response.headers        ...
 *      err.response.config         <same as err.config>
 *      err.response.data           <response from server>
 * -------------------------------------------------------------------------------------------------
 */
