import * as express from 'express';
import { pick } from 'lodash';
import { getLogger } from '../getLogger';
import { del, get, patch, post, put } from './api';
import { AxOptions, ReqRes } from './api.types';
import type { Endpoint } from './api.types';

const logger = getLogger('api');

let serviceName = '<unknown service>';

export function setServiceName(name: string): void {
    serviceName = name;
}

/**
 * A class to wrap the get/post/... methods in an layer that abstracts inter-service calls.
 *
 * Specifically, you pass the request object to the constructor, so that subsequent api calls will
 * include authentication headers.
 *
 * Note: We could also pass through additional headers (eg: x-amzn-trace-id).
 */
export class ApiWrapper {
    constructor(
        private req: Maybe<express.Request> = undefined,
        private asType: AsType = 'asService',
    ) {}

    async get<T extends EndpointTypes = [undefined, undefined]>(
        endpoint: Path<T>,
        opts: AxOptions = {},
    ): P<ResponseBody<T>> {
        return get(endpoint, this.options(opts)) as ResponseBody<T>;
    }

    async post<T extends EndpointTypes>(
        endpoint: Path<T>,
        body?: RequestBody<T>,
        opts: AxOptions = {},
    ): P<ResponseBody<T>> {
        return post(endpoint, body, this.options(opts)) as ResponseBody<T>;
    }

    async put<T extends EndpointTypes>(
        endpoint: Path<T>,
        body?: RequestBody<T>,
        opts: AxOptions = {},
    ): P<ResponseBody<T>> {
        return put(endpoint, body, this.options(opts)) as ResponseBody<T>;
    }

    async del<T extends EndpointTypes>(
        endpoint: Path<T>,
        body?: RequestBody<T>,
        opts: AxOptions = {},
    ): P<ResponseBody<T>> {
        return del(endpoint, body, this.options(opts)) as ResponseBody<T>;
    }

    async patch<T extends EndpointTypes>(
        endpoint: Path<T>,
        body?: RequestBody<T>,
        opts: AxOptions = {},
    ): P<ResponseBody<T>> {
        return patch(endpoint, body, this.options(opts)) as ResponseBody<T>;
    }

    options(opts: AxOptions): AxOptions {
        if (!this.req?.headers)
            return {
                ...opts,
                headers: {
                    ...opts.headers,
                    'x-via-endpoint': `[${serviceName}]`,
                },
            };

        const authHeaders = pick(this.req.headers, [
            'provider-issuer',
            'provider-tenant-id',
            'provider-user-id',
        ]);

        const { method, path } = this.req;
        const { authorization } = this.req.headers as Obj<string>;

        const customHeaders = {} as Obj<string>;
        const passThru = customHeaders as PassThroughCredentials;

        passThru['x-via-endpoint'] = `[${serviceName}] ${method} ${path}`;

        if (this.asType === 'asCaller') {
            // Pass through the auth headers
            Object.assign(customHeaders, authHeaders);
            if (authorization) {
                customHeaders.authorization = authorization;
            }
        } else {
            // as service
            if (authorization) {
                passThru['x-via-auth'] = authorization;
            }
            if (Object.keys(authHeaders).length) {
                passThru['x-via-auth-headers'] = JSON.stringify(authHeaders);
            }
        }

        // eslint-disable-next-line no-param-reassign
        opts.headers = {
            ...(opts.headers || {}),
            ...customHeaders,
        };

        logger.debug({ serviceName, authorization, headers: opts.headers });
        return opts;
    }
}

export default function wrap(req: express.Request, as: AsType = 'asService'): ApiWrapper {
    return new ApiWrapper(req, as);
}

type AsType = 'asCaller' | 'asService';

export type PassThroughCredentials = {
    'x-via-endpoint': string;
    'x-via-auth'?: string;
    'x-via-auth-headers'?: string;
};

export type EndpointTypes = Endpoint | OldEndpoint | ReqRes;

type OldEndpoint = {
    request: { body: unknown };
    response: { body: unknown };
};

export type Path<T extends EndpointTypes> = T extends Endpoint
    ? T['path']
    : T extends OldEndpoint | ReqRes
    ? string
    : never;

export type RequestBody<T extends EndpointTypes> = T extends Endpoint | OldEndpoint
    ? T['request']['body']
    : T extends ReqRes
    ? T[0]
    : never;

export type ResponseBody<T extends EndpointTypes> = T extends Endpoint | OldEndpoint
    ? T['response']['body']
    : T extends ReqRes
    ? T[1]
    : never;
