import { capitalizeAllWords, getInitials } from '@aurion/shared-functions/build/stringUtils';
import Icon from '@aurion/storybook-ui/components/Icon';
import { IconButton } from '@aurion/storybook-ui/components/IconButton';
import elId from '@aurion/storybook-ui/lib/elId';
import { faGrid2 } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import * as React from 'react';
import AllAppsMenu from '../AllAppsMenu/AllAppsMenu';
import { AurAppBarProps, NavItem, NestedItem } from '../AurAppBar.types';
import { styles } from './AppBar.styles';
import { GlobalPlatformAlert } from './GlobalPlatformAlert';

let previousPortal: string;

export default function AppBar(props: AppBarProps): RetEl {
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'));
    const isSm = useMediaQuery(useTheme().breakpoints.only('sm'));
    const viewMode = isXs ? 'mobile' : isSm ? 'compact' : 'full';

    let { currentPortal, navMenuItems } = props;

    const {
        logo,
        tenantLogo,
        tenantName,
        onShowAppsSidebar,
        onLogout,
        portalItems,
        onNavItemClick,
        currentPage,
        usernames,
        isAuthenticated,
        appBarRef,
    } = props;

    const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
    const [customerLogoErrored, setCustomerLogoErrored] = React.useState(false);
    const navSessionStorageName = 'aurion_navigation_no_portal';

    function navMenuItemOnClick(navItem: NavItem | NestedItem): OnClick {
        return (event) => onNavItemClick(navItem, event);
    }

    // They're in one of the portal states, save that in session storage for possible "portal-free" pages
    // istanbul ignore next
    if (currentPortal && navMenuItems.length > 0) {
        if (currentPortal !== previousPortal) {
            const stateToStore = btoa(JSON.stringify({ currentPortal, navMenuItems }));
            window.sessionStorage.setItem(navSessionStorageName, stateToStore);
            previousPortal = currentPortal;
        }
    } else {
        // Use session storage (if exists) to keep nav state
        const previousPortalStateStr = window.sessionStorage.getItem(navSessionStorageName);
        try {
            const previousPortalState = JSON.parse(atob(previousPortalStateStr || ''));
            currentPortal = previousPortalState.currentPortal;
            navMenuItems = previousPortalState.navMenuItems;
            // eslint-disable-next-line no-empty
        } catch (ignore) {}
    }

    return (
        <MuiAppBar
            ref={appBarRef}
            data-cmp="PageContainerUI/AppBar"
            position="fixed"
            elevation={isScrolled ? 4 : 0}
        >
            <GlobalPlatformAlert />
            <Toolbar variant="dense" sx={styles.toolbar}>
                <LeftLogo />
                <CentralNavigation />
                <RightPortalsAndProfileMenus />
            </Toolbar>
        </MuiAppBar>
    );

    function LeftLogo() {
        const homeNavMenuItem = navMenuItems.find((navMenuItem) => navMenuItem.label === 'Home');
        const onClick = homeNavMenuItem
            ? (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  onNavItemClick(navMenuItems[0], event)
            : undefined;

        return (
            <Grid sx={styles.leftLogoContainer}>
                <Link
                    href={homeNavMenuItem?.path}
                    onClick={onClick}
                    color="inherit"
                    underline="none"
                >
                    <Grid container>
                        <Grid item pr={1}>
                            <Box
                                component="img"
                                sx={styles.leftLogo}
                                alt="Aurion logo"
                                src={logo}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{ borderLeft: '1px solid white' }}
                            pl={1}
                            data-testid="tenant-logo-and-name"
                        >
                            {tenantLogo && !customerLogoErrored ? (
                                <Box
                                    component="img"
                                    sx={styles.leftLogo}
                                    src={tenantLogo}
                                    alt={capitalizeAllWords(tenantName)}
                                    onError={() => setCustomerLogoErrored(true)}
                                />
                            ) : (
                                capitalizeAllWords(tenantName)
                            )}
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
        );
    }

    function CentralNavigation() {
        if (!navMenuItems.length) {
            return <Box sx={styles.navMenusContainer} />;
        }

        return (
            <Box sx={styles.navMenusContainer}>
                {viewMode !== 'mobile' && (
                    <>
                        {navMenuItems.map((navMenuItem) => (
                            <NavMenuItem key={navMenuItem.id} item={navMenuItem} />
                        ))}

                        {/* The central navigation "AllApps" MENU button (with submenu) */}
                        <AutoNavButtonWithMenu
                            id="allApps"
                            label="All apps"
                            icon={faGrid2}
                            fixedWidth
                        >
                            <AllAppsMenu // Not an actual menu, but the menu content
                                navMenuItems={navMenuItems}
                                onNavItemClick={onNavItemClick}
                            />
                        </AutoNavButtonWithMenu>
                    </>
                )}
            </Box>
        );
    }

    function RightPortalsAndProfileMenus() {
        return (
            <Box sx={styles.rightMenusContainer}>
                {!!portalItems.length && viewMode === 'mobile' && (
                    /** The RHS AllApps SIDEBAR toggle button */
                    <CompactNavButton
                        {...elId({ id: 'allApps-sidebar--toggle-btn' })}
                        label="All apps"
                        icon={faGrid2}
                        onClick={onShowAppsSidebar}
                    />
                )}
                {viewMode === 'mobile' ? (
                    <PortalSwitchMenu portalItems={portalItems} />
                ) : (
                    <PortalSwitchButtons portalItems={portalItems} />
                )}

                {isAuthenticated ? (
                    <UserProfileMenuButton initials={getInitials(usernames)} />
                ) : null}
            </Box>
        );
    }

    // A single "central navigation" link, or menu-button (with submenu)
    function NavMenuItem(btnProps: NavMenuItemProps) {
        const { item } = btnProps;
        const { label, path: href, icon } = item;

        const commonProps = { label, icon };

        return item.path ? (
            <AutoNavLink
                {...elId({ id: item.id })}
                {...commonProps}
                highlight={item.path === currentPage}
                onClick={navMenuItemOnClick(item)}
                href={href}
            />
        ) : (
            <AutoNavButtonWithMenu
                id={item.id}
                {...commonProps}
                highlight={(item.items || []).some((subItem) => subItem.path === currentPage)}
            >
                {(item.items || []).map((subItem, idx) => (
                    <MenuItem
                        key={String(idx)}
                        {...elId({ id: `${subItem.id}--menu-item` })}
                        component={Link}
                        href={subItem.path}
                        onClick={navMenuItemOnClick(subItem)}
                    >
                        <Typography
                            sx={{ fontWeight: subItem.path === currentPage ? 'bold' : undefined }}
                        >
                            {subItem.label}
                        </Typography>
                    </MenuItem>
                ))}
            </AutoNavButtonWithMenu>
        );
    }

    // Portal switching - group of LINKs
    function PortalSwitchButtons({ portalItems: items }: Pick<AppBarProps, 'portalItems'>) {
        if (items.length < 2) return null;

        return (
            <>
                {items.map((item, idx) => (
                    <CompactNavLink
                        key={String(idx)}
                        label={`${item.label} View`}
                        href={item.path}
                        icon={item.icon}
                        onClick={navMenuItemOnClick(item)}
                        highlight={item.path === currentPortal}
                    />
                ))}
            </>
        );
    }

    // Portal switching - BUTTON (with menu)
    function PortalSwitchMenu({ portalItems: items }: Pick<AppBarProps, 'portalItems'>) {
        if (items.length < 2) return null;

        const selected = items.find((item) => item.path === currentPortal) || items[0];

        return (
            <AutoNavButtonWithMenu id="portal-switcher" label="Switch view" icon={selected.icon}>
                {(items || []).map((item, idx) => (
                    <MenuItem
                        key={String(idx)}
                        {...elId({ id: `${item.id}-` })}
                        onClick={navMenuItemOnClick(item)}
                    >
                        <Typography
                            sx={{ fontWeight: item.path === currentPortal ? 'bold' : undefined }}
                        >
                            {`${item.label} View`}
                        </Typography>
                    </MenuItem>
                ))}
            </AutoNavButtonWithMenu>
        );
    }

    // The round "user" button (with menu, and logout link)
    function UserProfileMenuButton({ initials }: UserProfileMenuButtonProps) {
        const id = 'profile-menu';
        const label = 'user profile';

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClick: React.ComponentProps<typeof Button>['onClick'] = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const popoverId = anchorEl ? `${id}-popover` : undefined;

        return (
            <>
                <Tooltip title="Open user profile menu">
                    <Button
                        {...elId({ id: `${id}--menu-btn` })}
                        sx={styles.accountsButton}
                        aria-label={`Open the ${label} menu`}
                        aria-describedby={popoverId}
                        onClick={handleClick}
                        variant="text"
                    >
                        {initials}
                    </Button>
                </Tooltip>
                <Menu
                    id={popoverId}
                    {...elId({ cmp: 'ProfilePopupMenu', id: `${id}--menu` })}
                    style={{ marginTop: '3px' }}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={() => {
                        setAnchorEl(null);
                    }}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MenuItem
                        {...elId({ id: 'logout--menu-item' })}
                        onClick={onLogout}
                        component={Link}
                        href="/logout/"
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function AutoNavLink(menuLinkProps: AutoNavLinkProps) {
        return viewMode === 'full' ? (
            <FullNavLink {...menuLinkProps} /> // Not actually used
        ) : (
            <CompactNavLink {...menuLinkProps} />
        );
    }

    // Button looking link - Show a tooltip, Hide the label
    function CompactNavLink(menuLinkProps: AutoNavLinkProps) {
        const { label, href, onClick, icon, highlight } = menuLinkProps;
        return (
            <Tooltip title={label}>
                <Link
                    sx={styles.navMenuItem(highlight)}
                    color="inherit"
                    underline="none"
                    href={href}
                    onClick={onClick}
                >
                    <Icon size="sm" icon={icon} />
                </Link>
            </Tooltip>
        );
    }

    // Button looking link - Hide the tooltip, Show the label
    function FullNavLink(menuLinkProps: AutoNavLinkProps) {
        const { label, href, onClick, icon, highlight } = menuLinkProps;

        return (
            <Link
                sx={styles.navMenuItem(highlight)}
                color="inherit"
                underline="none"
                href={href}
                onClick={onClick}
            >
                <Icon size="sm" icon={icon} />
                <Typography variant="caption" sx={styles.navMenuLabel}>
                    {label}
                </Typography>
            </Link>
        );
    }

    // A button with icon, no label, but hover text
    function CompactNavButton(menuButtonProps: AutoNavButtonProps) {
        const { label, onClick, icon, highlight, ...rest } = menuButtonProps;
        return (
            <Tooltip title={label}>
                <Button
                    {...rest}
                    sx={styles.navMenuItem(highlight)}
                    aria-label={`Open the ${label} menu`}
                    onClick={onClick}
                    variant="text"
                >
                    <Icon size="sm" icon={icon} />
                </Button>
            </Tooltip>
        );
    }

    function AutoNavButtonWithMenu(btnProps: AutoNavButtonWithMenuProps) {
        const { id, icon, label, children, highlight, fixedWidth, ...rest } = btnProps;

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClick: React.ComponentProps<typeof Button>['onClick'] = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const popoverId = anchorEl ? `${id}-popover` : undefined;

        const menuRest: AnyObject = {};

        return (
            <>
                {viewMode !== 'full' ? (
                    <Tooltip title={label}>
                        <Button
                            {...elId({ id: `${id}--menu-btn` })}
                            {...rest}
                            sx={styles.navMenuItem(highlight)}
                            aria-label={`Open the ${label} menu`}
                            aria-describedby={popoverId}
                            onClick={handleClick}
                            variant="text"
                        >
                            <Icon size="sm" icon={icon} />
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        {...elId({ id: `${id}--menu-btn` })}
                        {...rest}
                        sx={styles.navMenuItem(highlight)}
                        aria-label={`Open the ${label} menu`}
                        aria-describedby={popoverId}
                        onClick={handleClick}
                        variant="text"
                    >
                        <Icon size="sm" icon={icon} />
                        <Typography variant="caption" sx={styles.navMenuLabel}>
                            {label}
                        </Typography>
                    </Button>
                )}
                <Menu
                    {...elId({ id: `${id}--menu` })}
                    {...menuRest}
                    id={popoverId}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    PaperProps={{
                        sx: styles.popupMenu(fixedWidth),
                    }}
                >
                    {children}
                </Menu>
            </>
        );
    }
}

export type AppBarProps = Pick<
    AurAppBarProps,
    | 'logo'
    | 'tenantLogo'
    | 'tenantName'
    | 'onLogout'
    | 'navMenuItems'
    | 'portalItems'
    | 'currentPage'
    | 'currentPortal'
    | 'usernames'
    | 'isAuthenticated'
    | 'appBarRef'
> & {
    onNavItemClick: AurAppBarProps['onNavigate'];
    onShowAppsSidebar: React.ComponentProps<typeof IconButton>['onClick'];
};

type RetEl = ReturnType<React.FunctionComponent>;

type AutoNavLinkProps = {
    cmp?: string;
    id?: string;
    label: string;
    icon: NavItem['icon'];
    onClick?: OnClick;
    highlight?: boolean;
    href?: string;
};

type AutoNavButtonProps = {
    cmp?: string;
    id?: string;
    label: string;
    icon: NavItem['icon'];
    onClick?: OnClick;
    highlight?: boolean;
};

type AutoNavButtonWithMenuProps = React.PropsWithChildren<{
    id: string;
    'data-cmp'?: string;
    'data-testid'?: string;
    'data-cy'?: string;
    label: string;
    icon: NavItem['icon'];
    highlight?: boolean;
    fixedWidth?: boolean;
}>;

type NavMenuItemProps = { item: NavItem };

type UserProfileMenuButtonProps = {
    initials: string;
};
