import {
    AppId,
    PortalType,
    ZippedWebPermissions,
} from '@aurion/shared-functions/build/auth/permissions/permissions.types';
import { PermissionActionVerb } from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import { difference, isArray, isPlainObject, keys, uniq } from 'lodash';

const featureNameMap: Record<string, Record<string, string>> = {
    administration: {
        attendanceTypes: 'Attendance Types',
        companies: 'Companies',
        accountsCode: 'Accounts Code',
        costCategory: 'Cost Codes',
        costProfile: 'Cost Profiles',
        postingRules: 'Posting Rules',
        employmentConditions: 'Employment Conditions',
        employmentLocations: 'Employment Locations',
        employmentTypes: 'Employment Types',
        leaveTypes: 'Leave Types',
        notifications: 'Notifications',
        movementReasons: 'Movement Reasons',
        payCodes: 'Pay Codes',
        payGroups: 'Pay Groups',
        permissions: 'Permissions',
        publicHolidays: 'Public Holidays',
        superSchemes: 'Super Schemes',
        settings: 'Settings',
        employees: 'Employees',
        externalUsers: 'External Users',
    },
    insights: {
        auditing: 'Auditing',
        leave: 'Leave',
    },
    people: {
        employees: 'Employees',
        personalDetails: 'Personal Details',
        bankTaxSuper: 'Bank, Tax & Super',
        payroll: 'Payroll',
        employmentDetails: 'Employment Details',
        leave: 'Leave',
        onboarding: 'Onboarding',
        separations: 'Separations',
        organisationStructure: 'Organisation Structure',
    },
    payroll: {
        payruns: 'Payrun',
        endOfFinancialYearFinalisation: 'End Of Financial Year Finalisation',
    },
};

const appNameMap: Record<AppId, string> = {
    administration: 'Administration',
    insights: 'Insights',
    people: 'People',
    payroll: 'Payroll',
};

const actionVerbMap: Record<PermissionActionVerb, string> = {
    new: 'Create',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
};

/* Perform a diff between two versions of zipped web permissions.

  old: { admin: { administration: { accounts: ['read', 'write'], ...}}}
  vs
  new: { admin: { administration: { accounts: ['read', 'delete'], ...}}}

  => [<added>, <removed>]
  => [{ 'admin.administration.accounts: ['delete'], ...}, { 'admin.administration.accounts: ['write'], ...}]
*/
export function diffZippedWebPermissions(
    oldPermissions: ZippedWebPermissions,
    newPermissions: ZippedWebPermissions,
): Record<string, PermissionActionVerb[]>[] {
    const added: Record<string, PermissionActionVerb[]> = {};
    const removed: Record<string, PermissionActionVerb[]> = {};

    function processFeatures(
        portalKey: PortalType,
        appKey: AppId,
        oldApp: Record<AppId, PermissionActionVerb[]>,
        newApp: Record<AppId, PermissionActionVerb[]>,
    ) {
        const featureKeys = uniq([...keys(oldApp || {}), ...keys(newApp || {})]);

        for (const featureKey of featureKeys) {
            const oldFeaturePermissions = oldApp ? oldApp[featureKey] : undefined;
            const newFeaturePermissions = newApp ? newApp[featureKey] : undefined;

            if (isArray(oldFeaturePermissions) || isArray(newFeaturePermissions)) {
                const addedPermissions = difference<PermissionActionVerb>(
                    newFeaturePermissions || [],
                    oldFeaturePermissions || [],
                );
                const removedPermissions = difference<PermissionActionVerb>(
                    oldFeaturePermissions || [],
                    newFeaturePermissions || [],
                );

                const featurePath = `${portalKey}.${appKey}.${featureKey}`;
                if (addedPermissions.length) {
                    added[featurePath] = addedPermissions;
                }

                if (removedPermissions.length) {
                    removed[featurePath] = removedPermissions;
                }
            }
        }
    }

    const portalKeys = uniq([...keys(oldPermissions), ...keys(newPermissions)]);
    for (const portalKey of portalKeys) {
        const oldPortal = oldPermissions[portalKey];
        const newPortal = newPermissions[portalKey];

        if (isPlainObject(oldPortal) && isPlainObject(newPortal)) {
            const appKeys = uniq([...keys(oldPortal || {}), ...keys(newPortal || {})]);

            for (const appKey of appKeys) {
                const oldApp = oldPortal[appKey];
                const newApp = newPortal[appKey];

                if (isPlainObject(oldApp) || isPlainObject(newApp)) {
                    processFeatures(portalKey, appKey, oldApp, newApp);
                }
            }
        }
    }

    return [added, removed];
}

export function formatUpdatedPermissionsInfo(
    updatedPermissions: [
        Record<string, PermissionActionVerb[]>,
        Record<string, PermissionActionVerb[]>,
    ],
): [string[], string[]] {
    const [added, removed] = updatedPermissions;

    const addedLabels: string[] = [];
    const removedLabels: string[] = [];

    keys(added).forEach((key) => {
        const [portal, app, feature] = key.split('.');

        // For portals other than admin, the popup would show a generic message.
        if (portal !== 'admin') return;

        addedLabels.push(
            [
                appNameMap[app],
                featureNameMap[app][feature],
                added[key].map((verb: PermissionActionVerb) => actionVerbMap[verb]).join(', '),
            ].join(' > '),
        );
    });
    keys(removed).forEach((key) => {
        const [portal, app, feature] = key.split('.');

        // For portals other than admin, the popup would show a generic message.
        if (portal !== 'admin') return;

        removedLabels.push(
            [
                appNameMap[app],
                featureNameMap[app][feature],
                removed[key].map((verb: PermissionActionVerb) => actionVerbMap[verb]).join(', '),
            ].join(' > '),
        );
    });

    return [addedLabels, removedLabels];
}
