import { capitalize, upperFirst } from 'lodash';

/**
 * Helper to get a friendly error message or ZodError given an error of unknown form.
 *
 * @param error
 * @param messagePrefix The text to use at the beginning of the error message
 */
export function getFriendlyErrorMessage(
    error: unknown,
    messagePrefix = 'Something went wrong',
): string {
    const fixedMessagePrefix = capitalize(messagePrefix).replace(/\W$/, '');

    const errorMessage = getErrorMessage(error);

    if (errorMessage) {
        // TODO: DLV-4638 Should be handling the formatting and casing in appErrors
        const fixedErrorMessage = upperFirst(errorMessage).replace(/\W$/, '');

        return `${fixedMessagePrefix}: ${fixedErrorMessage}.`;
    }

    return `${fixedMessagePrefix}.`;
}

function getErrorMessage(error: unknown): string | undefined {
    if (typeof error === 'string') {
        return error;
    }
    return typeof error === 'object' && !!error && 'message' in error
        ? String((error as Record<string, unknown>).message)
        : undefined;
}
