import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';

export type Props = Pick<
    FontAwesomeIconProps,
    'style' | 'fixedWidth' | 'title' | 'className' | 'rotation'
> & {
    icon: IconDefinition;
    size?: IconSizes | number;
    color?: IconColors;
};

export default React.forwardRef<SVGSVGElement, Props>(
    ({ color, size = 'md', icon, style, ...rest }, ref) => {
        const theme = useTheme();

        const iconStyles: React.CSSProperties = {
            verticalAlign: 'middle',
            fontSize: `${convertSizeToPixelHeight(size)}px`,
            height: `${convertSizeToPixelHeight(size)}px`,
            flexShrink: 0,
        };

        return (
            <FontAwesomeIcon
                icon={icon}
                color={getThemedColor(color, theme)}
                style={{ ...iconStyles, ...style }}
                // Ref forwarding:
                // Props need to be spread onto the same element that the ref is applied to.
                ref={ref}
                {...rest}
            />
        );
    },
);

function getThemedColor(color: IconColors | undefined, theme: Theme) {
    const isDark = theme.palette.mode === 'dark';
    if (color === 'dark') return theme.palette.common[isDark ? 'white' : 'black'];
    if (color === 'light') return theme.palette.common[isDark ? 'black' : 'white'];
    if (color === 'primary') return theme.palette.primary.main;
    if (color === 'secondary') return theme.palette.secondary.main;
    if (color === 'error') return theme.palette.error.main;
    if (color === 'warning') return theme.palette.warning.main;
    if (color === 'info') return theme.palette.info.main;
    if (color === 'success') return theme.palette.success.main;
    return 'inherit';
}

const sizeToHeight: Record<IconSizes, number> = { sm: 16, md: 24, lg: 32, xl: 40 };

function convertSizeToPixelHeight(size: IconSizes | number) {
    return typeof size === 'number' ? size : sizeToHeight[size];
}

export type IconSizes =
    | 'sm' // 16px
    | 'md' // 24px
    | 'lg' // 32px
    | 'xl'; // 40px

export type IconColors =
    | 'dark'
    | 'light'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'inherit'
    | 'default';
