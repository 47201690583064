import {
    DestructuredWebAction,
    ZippedWebPermissions,
} from '@aurion/shared-functions/build/auth/permissions/permissions.types';
import {
    PermissionAction,
    PermissionActionVerb,
} from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import {
    CostingModuleTabItem,
    EmployeeModuleTabItem,
    PeopleManagementModuleTabItem,
    UserModuleTabItem,
} from '../moduleTab.types';
import { Session } from '../session.types';

/*
   Returns true if userPermissions has at least one of the requiredPermissions
*/
export function assertUserHasRequiredPermissions({
    userPermissions,
    requiredPermissions,
}: AssertUserHasRequiredPermissionsArgs) {
    for (const requiredPermission of requiredPermissions) {
        const { portal, app, feature, action } = destructureWebAction(requiredPermission);
        const allowed = !!userPermissions?.[portal]?.[app]?.[feature]?.includes(
            action as PermissionActionVerb,
        );
        if (allowed) return true;
    }

    return false;
}

/*
    Destructures a web action string ie 'web:admin/people/peopleManagement-edit'
    in to an object ie
    {
        portal: 'admin',
        app: 'people',
        feature: 'peopleManagement',
        action: 'edit',
    }
*/
export function destructureWebAction(webAction: PermissionAction): DestructuredWebAction {
    const [path, action] = webAction.split(':')[1].split('-');
    const [portal, app, feature] = path.split('/');

    return {
        portal,
        app,
        feature,
        action,
    };
}

type AssertUserHasRequiredPermissionsArgs = {
    userPermissions: ZippedWebPermissions;
    requiredPermissions: PermissionAction[];
};

export function getPermittedTabs(
    session: Session,
    tabs: (
        | EmployeeModuleTabItem
        | UserModuleTabItem
        | CostingModuleTabItem
        | PeopleManagementModuleTabItem
    )[],
) {
    return tabs.filter((item) =>
        assertUserHasRequiredPermissions({
            userPermissions: session.user?.permissions ?? {},
            requiredPermissions: item.permissions as PermissionAction[],
        }),
    );
}
