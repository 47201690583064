import { PermissionAction } from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import { useSession } from '../../../hooks/useSession';
import { assertUserHasRequiredPermissions } from './permissions.utils';

export function useAccessControl() {
    const { session } = useSession();

    const permissionCheck = (permissions: PermissionAction | PermissionAction[]) =>
        assertUserHasRequiredPermissions({
            userPermissions: session.user?.permissions ?? {},
            requiredPermissions: typeof permissions === 'string' ? [permissions] : permissions,
        });

    return {
        hasPermission(permissions: PermissionAction | PermissionAction[]): boolean {
            return permissionCheck(permissions);
        },

        returnIfPermitted<T>(
            permissions: PermissionAction | PermissionAction[],
            thing: T,
        ): Maybe<T> {
            return permissionCheck(permissions) ? thing : undefined;
        },
    };
}
