/**
 * There was a problem making an outbound request.
 */
/** @deprecated Use appErrors instead */
class RequestError extends Error {
    public name = 'RequestError';
    public status = RequestError.Status.upstreamBad;
    // Other valid responses to consider when specifying:
    //      502 Bad Gateway         - this server, while working as a gateway to get a response needed to handle the request, got an invalid response.
    //      503 Service Unavailable - this server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded. Retry-After: HTTP header should, if possible, contain the estimated time before the recovery of the service. The webmaster must also take care about the caching-related headers that are sent along with this response, as these temporary condition responses should usually not be cached.
    //      504 Gateway Timeout     - this server is acting as a gateway and cannot get a response in time.

    public context = undefined as Context | undefined;

    constructor(message: string, props: Props) {
        super(message);

        Object.assign(this, props);
        Object.setPrototypeOf(this, RequestError.prototype);

        /* istanbul ignore next */
        const stackArr = (this.stack || '').split('\n');
        stackArr.length = Math.min(stackArr.length, 2); // truncate the array
        this.stack = stackArr.join('\n');
    }
}

/** @deprecated X */
// prettier-ignore
namespace RequestError { // eslint-disable-line @typescript-eslint/no-namespace
    export enum Status {
        upstreamBad = 502,
        unavailable = 503,
        upstreamTimeout = 504,
    }
}

/** @deprecated Use appErrors instead */
export default RequestError;

type Props = {
    status?: RequestError.Status;
    context?: Context & AnyObject;
};

type Context = {
    req?: AnyObject;
    res?: AnyObject;
};
