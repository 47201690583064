import { useSession } from '../hooks/useSession';
import { Tenant } from './auth/session.types';

export default function useTenant(): Tenant {
    const {
        session: { tenant },
    } = useSession();

    return (
        tenant ?? {
            tenantId: '',
            tenantAlias: '',
            tenantName: '',
            tenantLogoURL: '',
            tenantTimeZone: '',
        }
    );
}
