import {
    GetGlobalPlatformAlertAction,
    UpdateGlobalPlatformAlertAction,
} from '@aurion/service-global-config/src/platform/alert/alert.controller.types';
import BaseService from '../baseService';

const { api } = new BaseService();

export async function getGlobalPlatformAlert(minuteOfHour: number) {
    return api().get<GetGlobalPlatformAlertAction>(`/globalconfig/platform/alert/${minuteOfHour}`);
}

export async function updateGlobalPlatformAlert(
    updatedAlert: UpdateGlobalPlatformAlertAction['request']['body'],
) {
    return api().post<UpdateGlobalPlatformAlertAction>(
        '/globalconfig/platform/alert',
        updatedAlert,
    );
}
