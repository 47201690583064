import { GetGlobalPlatformAlertAction } from '@aurion/service-global-config/platform/alert/alert.controller.types';
import { Alert, AlertColor } from '@mui/material';
import { useLoadRequest } from '../../../../lib/requests/useLoadRequest';
import { getGlobalPlatformAlertQuery } from '../../../../services/globalPlatformAlerts/globalPlatformAlerts.queries';

function getSeverity(level: GetGlobalPlatformAlertAction['response']['body']['level']): AlertColor {
    switch (level) {
        case 'error':
            return 'error';
        case 'warn':
            return 'warning';
        default:
            return 'info';
    }
}

export function GlobalPlatformAlert() {
    const { data: platformAlert } = useLoadRequest(getGlobalPlatformAlertQuery(), {
        // Poll for new platform alert every 20 seconds.
        refetchInterval: 20000,
    });

    return platformAlert?.enabled ? (
        <Alert
            severity={getSeverity(platformAlert.level)}
            variant="filled"
            sx={{ borderRadius: 0 }}
        >
            {platformAlert.message}
        </Alert>
    ) : (
        <></>
    );
}
