import { QueryKey, UseMutationResult, useQueryClient } from '@tanstack/react-query';

export type Query<T> = { queryKey: QueryKey; queryFn: () => P<T> };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryFunction<T> = (...args: any[]) => { queryKey: string[]; queryFn: () => P<T> };

export function useQueryHelpers() {
    const queryClient = useQueryClient();

    /**
     * Returns the stored data for the given query
     */
    const getQueryData = <T>(query: Query<T>) => queryClient.getQueryData<T>(query.queryKey);

    /**
     * Acts as a PUT operation on the query data
     * If an item exists with the given item's id it will be replaced,
     * otherwise it will be added
     */
    const setQueryDataItem = <T extends { id?: string }>(
        query: Query<T[]>,
        updatedItem: T,
        identifierAttribute: keyof T = 'id',
    ) => {
        queryClient.setQueryData(query.queryKey, (currentItems: T[] = []) => [
            ...currentItems.filter(
                (item) => item[identifierAttribute] !== updatedItem[identifierAttribute],
            ),
            updatedItem,
        ]);
    };

    /**
     * Acts like a PATCH operation on the data of the given query
     * The existing query data will be passed as an argument to the mutator function,
     * and the returned value will replace the initial value
     */
    const mutateQueryData = <U, T extends Maybe<U>>(
        query: Query<T>,
        mutator: (data: Maybe<T>) => Maybe<T>,
    ) => {
        queryClient.setQueryData(query.queryKey, (currentData: Maybe<T>) => mutator(currentData));
    };

    /**
     * Acts like a PATCH operation on an item in the query data
     * The item with the given id will be passed as an argument to the itemMutator function,
     * and the returned value will replace the initial value
     */
    const mutateQueryDataItem = <T extends { id?: string }>(
        query: Query<T[]>,
        updatedItemIdentifier: T[keyof T],
        itemMutator: (item: T) => T,
        identifierAttribute: keyof T = 'id',
    ) => {
        queryClient.setQueryData(query.queryKey, (currentItems: T[] = []) => {
            const currentItem = currentItems.find(
                (item) => item[identifierAttribute] === updatedItemIdentifier,
            );

            if (!currentItem) return currentItems;

            const updatedItem = itemMutator(currentItem);

            return [
                ...currentItems.filter(
                    (item) => item[identifierAttribute] !== updatedItemIdentifier,
                ),
                updatedItem,
            ];
        });
    };

    /**
     * Acts like a DELETE operation on an item in the query data
     */
    const removeQueryDataItem = <T extends { id?: string }>(
        query: Query<T[]>,
        itemIdentifier: T[keyof T],
        identifierAttribute: keyof T = 'id',
    ) => {
        queryClient.setQueryData(query.queryKey, (currentItems: T[] = []) => [
            ...currentItems.filter((item) => item[identifierAttribute] !== itemIdentifier),
        ]);
    };

    /**
     * Invalidates the query data for the given queries
     */
    const invalidateQueryData = (...queries: Query<unknown>[]) => {
        queries.forEach((query) => queryClient.invalidateQueries(query.queryKey));
    };

    return {
        getQueryData,
        setQueryDataItem,
        mutateQueryData,
        mutateQueryDataItem,
        removeQueryDataItem,
        invalidateQueryData,
    };
}

export function is401(error: unknown): boolean {
    if (hasCodeOrStatus(error)) {
        const { status, code } = error;

        const statusCode = status || code || 0;

        return statusCode === 401;
    }

    return false;
}

export function isSessionExpiredError(error: unknown): boolean {
    const err = error as { message?: string; code?: number } | undefined;
    return /user session has expired/i.test(err?.message || '') && err?.code === 403;
}

export function shouldResyncSessionError(error: unknown): boolean {
    const err = error as { message?: string; code?: number } | undefined;
    return /session needs to be refetched/i.test(err?.message || '') && err?.code === 403;
}

export function combineMutations(
    mutations: readonly Pick<UseMutationResult, 'isLoading' | 'reset' | 'isError' | 'error'>[],
) {
    return {
        isLoading: mutations.some((mutation) => mutation.isLoading),
        reset: () => {
            mutations.forEach((mutation) => mutation.reset());
        },
        error: mutations.find((mutation) => mutation.isError)?.error,
    };
}

export function hasCodeOrStatus(error: unknown): error is { code?: number; status?: number } {
    return !!error && typeof error === 'object' && ('code' in error || 'status' in error);
}
