/* eslint-disable class-methods-use-this */
/* istanbul ignore file */
import { getSession } from '../hooks/useSession';
import ApiService from './apiService';

export default class BaseService {
    api(): ApiService {
        return ApiService.getInstance();
    }

    /** @deprecated Use getTenantId or Alias instead */
    getTenant = () => this.getTenantId();

    getTenantId(): string {
        const { session } = getSession();
        const realTenantId = session.tenant?.tenantId;
        if (!realTenantId) throw new Error('Could not get tenantId from Session.');
        return realTenantId;
    }

    getTenantAlias(): string {
        const { session } = getSession();
        const tenant = session.tenant?.tenantAlias;
        if (!tenant) throw new Error('Could not get tenant from Session.');
        return tenant;
    }
}
