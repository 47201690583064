import Loading from '@aurion/storybook-ui/components/Loading';
import { Stack } from '@mui/material';
import * as React from 'react';
import AurAppBar from '../AurAppBar';
import { PageContainerUIProps } from './PageContainerUI.types';

export default function PageContainerUI(props: PageContainerUIProps): JSX.Element {
    const {
        logo,
        tenantLogo,
        tenantName,
        navMenuItems,
        portalItems,
        loading,
        children,
        currentPage,
        currentPortal,
        onLogout,
        onNavigate,
        usernames,
        isAuthenticated,
    } = props;

    const [appBarHeightOffset, setAppBarHeight] = React.useState(0);

    /**
     * Callback to get a reference to the app bar rendered at the top of the app.
     *
     * We use this reference to get the height of the app bar so we can set the
     * height of the spacer in this component.
     *
     * The app bar height may change when the platform alert is changed.
     *
     * We use a ResizeObserver to subscribe to height changes in the app bar so
     * the spacer is always the same height as the app bar.
     *
     * Using ResizeObserver in React: https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
     */
    const appBarRef = React.useCallback((node: HTMLDivElement) => {
        if (node) {
            const resizeObserver = new ResizeObserver((entries) => {
                const entry = entries[0];

                if (!entry) return;

                setAppBarHeight(entry.contentRect.height);
            });
            resizeObserver.observe(node);
        }
    }, []);

    return (
        <Stack data-cmp="PageContainerUI">
            <AurAppBar
                logo={logo}
                tenantLogo={tenantLogo}
                tenantName={tenantName}
                navMenuItems={navMenuItems}
                portalItems={portalItems}
                currentPage={currentPage}
                currentPortal={currentPortal}
                onLogout={onLogout}
                onNavigate={onNavigate}
                usernames={usernames}
                appBarRef={appBarRef}
                isAuthenticated={isAuthenticated}
            />

            <Stack component="main" flexGrow={1} sx={{ minHeight: '100vh' }}>
                {/* spacer to push page content down same height as AurAppBar */}
                <div style={{ minHeight: `${appBarHeightOffset}px` }} />

                {/* Loading progress bar just under (and touching) AurAppBar */}
                {loading && <Loading tid="PageContainer" />}

                <Stack
                    sx={{
                        flexGrow: 1,
                        textAlign: 'left',
                        maxWidth: '100vw', // Prevent children from expanding content outside of viewport
                    }}
                    data-testid="PageContainer-childrenContainer"
                >
                    {children}
                </Stack>
            </Stack>
        </Stack>
    );
}
