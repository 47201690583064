import Big, { BigSource } from 'big.js';
import { inRange } from 'lodash';

export function toVariablePrecision(
    value: number | Big,
    minimumPrecision: number,
    maximumPrecision: number,
): string {
    return Number(value.toFixed(maximumPrecision)).toLocaleString(undefined, {
        minimumFractionDigits: minimumPrecision,
        maximumFractionDigits: maximumPrecision,
    });
}

export function isPositiveInteger(n: number): boolean {
    return n >= 0 && Number.isInteger(n);
}

export function comparePrecision(n: number, precision: number): boolean {
    const str = n.toString();
    return str.indexOf('.') >= 0 ? str.split('.')[1].length <= precision : true;
}

/**
 * Convert a number to accounting format.
 * eg: Given number and currency symbol: 1000, '$'
 *     Result => "$1,000.00"
 *     Given number and currency symbol: -1000, '$'
 *     Result => "-$1,000.00"
 */
export function toAccountingFormat(number: number, currencySymbol = '', precision = 2): string {
    const sign = number < 0 ? '-' : '';

    return `${sign}${currencySymbol}${Math.abs(number).toLocaleString(undefined, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    })}`;
}

/**
 * Present a number as a percentage.
 *
 * If precision is not provided, the fractional component will either show
 * zero, 2 or 5 decimal places, depending on the number provided.
 *
 * Number is expected to between 0 and 100 (not 0 -> 1)
 */
export function toPercentageFormat(number: number, precision?: 0 | 2 | 5): string {
    if (precision === undefined) {
        const [, mantisa] = String(number).split('.');
        // eslint-disable-next-line no-param-reassign
        precision = !mantisa ? 0 : mantisa.length < 3 ? 2 : 5;
    }

    const value = Number(number).toLocaleString(undefined, {
        minimumFractionDigits: precision,
    });

    return `${value}%`;
}

export function sum(array: BigSource[]): Big {
    return array.reduce<Big>((total, value) => total.add(value), Big(0));
}

export function toDisplayCleanFixed(number: number, precision: number): string {
    if (Number.isInteger(parseFloat(number.toFixed(precision)))) {
        return number.toFixed(0);
    }
    return number.toFixed(precision);
}

export function formatPrecisionToFiveOrLess(amount: number): number | string {
    if (comparePrecision(amount, 0)) {
        return amount.toFixed(1);
    }
    if (!comparePrecision(amount, 5)) {
        return Number(amount.toFixed(5));
    }
    return amount;
}

/**
 * Convert a string to a number, dropping any decimal point component.
 */
export function parseInteger<T>(numberAsString: string | undefined, defaultValue?: T): number | T {
    if (!numberAsString) return defaultValue as T;

    const parsed = Number.parseInt(numberAsString, 10);

    return Number.isNaN(parsed)
        ? (defaultValue as T) //
        : parsed;
}

export function getMaxValue(mandatoryValue: BigSource, ...values: BigSource[]): Big; // Ensures that at least one argument is always provided
export function getMaxValue(...values: BigSource[]): Big {
    const bigValues = values.map((value) => (value instanceof Big ? value : Big(value)));

    return bigValues
        .slice(1)
        .reduce(
            (greatestValue, currentValue) =>
                currentValue.gt(greatestValue) ? currentValue : greatestValue,
            bigValues[0],
        );
}

/**
 * Return the given `value` if it is between `min` and `max`, otherwise return either `min` or `max`
 * depending on if `value` is less than or greater than the respective argument.
 */
export function clamp(value: BigSource, min: BigSource, max: BigSource): Big {
    const bigValue = Big(value);

    if (bigValue.gt(max)) {
        return Big(max);
    }

    if (bigValue.lt(min)) {
        return Big(min);
    }

    return bigValue;
}

export function add(mandatoryValue: BigSource, ...values: BigSource[]): number; // Ensures that at least one argument is always provided
export function add(...values: BigSource[]): number {
    const [first, ...rest] = values;
    let sumTotal = Big(first);

    for (const next of rest) {
        sumTotal = sumTotal.add(next);
    }

    return sumTotal.toNumber();
}

// A wrapper around lodash inRange to make start and end values inclusive
export function inRangeInclusive(num: number, start = 0, end = 0) {
    // flip if range is negative
    const startAdjusted = start < 0 ? start + 1 : start - 1;
    const endAdjusted = end < 0 ? end - 1 : end + 1;
    return inRange(num, startAdjusted, endAdjusted);
}
