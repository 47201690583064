import { objectEntries } from '@aurion/shared-functions';
import {
    PermissionAction,
    Portal,
} from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import {
    faBuildingShield,
    faCalendarCheck,
    faChartSimple,
    faDollarSign,
    faFileUser,
    faGear,
    faHouse,
    faPeople,
    faPlaneEngines,
    faUser,
    faUserGear,
    faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { assertUserHasRequiredPermissions } from '../../lib/auth/permissions/permissions.utils';
import { Session } from '../../lib/auth/session.types';
import { AurAppBarProps } from './AurAppBar';
import { PageContainerUIProps } from './PageContainerUI';

export const portalItems: Record<Portal, NavItem> = {
    global: navItem('portal:global', 'Managed Services', faBuildingShield, '/aurionadmin/'),
    admin: navItem('portal:admin', 'Payroll', faUserGear, '/admin/'),
    manager: navItem('portal:manager', 'Manager', faUsers, '/team/'),
    employee: navItem('portal:employee', 'Employee', faUser, '/me/'),
};

export const navMenuItemsHome: PageContainerUIProps['navMenuItems'] = [
    navItem('my:home', 'Home', faHouse, '/'),
];

export const navMenuItemsMe: PageContainerUIProps['navMenuItems'] = [
    navItem('my:home', 'Home', faHouse, '/me/'),
    navItem('my:payroll', 'Pay', faDollarSign, undefined, [
        navSubItem('my:payslips', 'Pay History & Payslips', '/me/mypayroll/'),
        navSubItem('my:finance', 'Bank, Tax & Super', '/me/finance/'),
    ]),
    navItem('my:leave', 'Leave', faPlaneEngines, '/me/leave/'),
    navItem('my:details', 'My Details', faFileUser, '/me/mydetails/'),
];

export const navMenuItemsManager: PageContainerUIProps['navMenuItems'] = [
    navItem('man:home', 'Home', faHouse, '/team/'),

    navItem('man:availability', 'Availability', faCalendarCheck, '/team/availability/'),
    navItem('man:leave', 'Leave', faPlaneEngines, '/team/leave/'),
    navItem('man:people', 'People', faPeople, undefined, [
        navSubItem(
            'web:manager/people/salaries-view',
            'Employee Salaries',
            '/team/people/salaries/',
        ),
    ]),
];

export const navMenuItemsAdmin: PageContainerUIProps['navMenuItems'] = [
    navItem('web:admin/home', 'Home', faHouse, '/admin/'),

    navItem('web:admin/people', 'People', faPeople, undefined, [
        navSubItem(
            'web:admin/people/personalDetails-view',
            'Employees',
            '/admin/people/employees/',
            [
                'web:admin/people/employmentDetails-view',
                'web:admin/people/personalDetails-view',
                'web:admin/people/bankTaxSuper-view',
                'web:admin/people/payroll-view',
                'web:admin/people/leave-view',
            ],
        ),
        navSubItem(
            'web:admin/people/organisationStructure-view',
            'Organisation Structure',
            '/admin/people/organisation-structure/',
        ),
        navSubItem(
            'web:admin/people/onboarding-view',
            'People Management',
            '/admin/people-management/',
            ['web:admin/people/onboarding-view', 'web:admin/people/separations-view'],
        ),
    ]),

    navItem('web:admin/payroll', 'Payroll', faDollarSign, undefined, [
        navSubItem('web:admin/payroll/payruns-view', 'Pay Runs', '/admin/payroll/pay-runs/'),
        navSubItem(
            'web:admin/payroll/payAdvances-view',
            'Pay Advances',
            '/admin/payroll/pay-advances/',
        ),
        navSubItem(
            'web:admin/payroll/endOfFinancialYearFinalisation-view',
            'EOFY Finalisation',
            '/admin/payroll/end-of-financial-year-finalisation/',
        ),
    ]),

    navItem('web:admin/insights', 'Insights', faChartSimple, undefined, [
        navSubItem('web:admin/insights/auditing-view', 'Audit Log', '/admin/insights/audit-log/'),
        navSubItem('web:admin/insights/leave-view', 'Leave', '/admin/insights/leave/'),
    ]),

    navItem('web:admin/administration', 'Administration', faGear, undefined, [
        navSubItem(
            'web:admin/administration/attendanceTypes-view',
            'Attendance Types',
            '/admin/administration/attendance-types/',
        ),
        // INFO: Hide this as we do not need this right now
        // navSubItem(
        //     'admin:classifications',
        //     'Classifications',
        //     '/admin/organisation/classifications/',
        // ),
        navSubItem(
            'web:admin/administration/companies-view',
            'Companies',
            '/admin/organisation/companies/',
        ),
        navSubItem(
            'web:admin/administration/accountsCode-view',
            'Costing',
            '/admin/organisation/costing/',
            [
                'web:admin/administration/accountsCode-view',
                'web:admin/administration/costCategory-view',
                'web:admin/administration/costProfile-view',
                'web:admin/administration/postingRules-view',
                'web:admin/administration/ledgerOutput-view',
            ],
        ),
        navSubItem(
            'web:admin/administration/employmentConditions-view',
            'Employment Conditions',
            '/admin/administration/employment-conditions/',
        ),
        navSubItem(
            'web:admin/administration/employmentLocations-view',
            'Employment Locations',
            '/admin/administration/employment-locations/',
        ),
        navSubItem(
            'web:admin/administration/employmentTypes-view',
            'Employment Types',
            '/admin/administration/employment-types/',
        ),
        navSubItem(
            'web:admin/administration/leaveCategories-view',
            'Leave',
            '/admin/administration/leave/',
            [
                'web:admin/administration/leaveCategories-view',
                'web:admin/administration/leaveTypes-view',
            ],
        ),
        navSubItem(
            'web:admin/administration/movementReasons-view',
            'Movement Reasons',
            '/admin/administration/movement-reasons/',
        ),
        navSubItem(
            'web:admin/administration/notifications-view',
            'Notifications',
            '/admin/administration/notifications/',
        ),
        navSubItem(
            'web:admin/administration/payCodes-view',
            'Pay Codes',
            '/admin/administration/pay-codes/',
        ),
        navSubItem(
            'web:admin/administration/payGroups-view',
            'Pay Groups',
            '/admin/organisation/pay-groups/',
        ),
        navSubItem(
            'web:admin/administration/permissions-view',
            'Permissions',
            '/admin/administration/permissions/',
        ),
        navSubItem(
            'web:admin/administration/publicHolidays-view',
            'Public Holidays',
            '/admin/administration/public-holidays/',
        ),
        navSubItem(
            'web:admin/administration/superSchemes-view',
            'Super Schemes',
            '/admin/administration/super-schemes/',
        ),
        navSubItem(
            'web:admin/administration/tenantSettings-view',
            'Settings',
            '/admin/administration/settings/',
        ),
        navSubItem(
            'web:admin/administration/thirdParties-view',
            'Third Parties',
            '/admin/administration/third-parties/',
        ),
        navSubItem(
            'web:admin/administration/employees-view',
            'User Management',
            '/admin/administration/user-management/',
            [
                'web:admin/administration/employees-view',
                'web:admin/administration/externalUsers-view',
            ],
        ),
        // TODO: Move the pages around to match nav structure
    ]),
];

export const navMenuItemsGlobal: PageContainerUIProps['navMenuItems'] = [
    navItem('glob:home', 'Home', faHouse, '/aurionadmin/'),

    navItem('glob:config', 'Configuration', faGear, undefined, [
        navSubItem('glob:bsbList', 'BSB List', '/aurionadmin/product-config/bsb/'),
        navSubItem(
            'glob:super',
            'Super Rates & Thresholds',
            '/aurionadmin/product-config/global-super-config/',
        ),
        navSubItem(
            'glob:taxTables',
            'Tax Rates & Thresholds',
            '/aurionadmin/product-config/tax-rates-and-thresholds/',
        ),
        navSubItem('glob:platformAlerts', 'Platform Alerts', '/aurionadmin/platform/alerts/'),
        navSubItem('glob:platformOutage', 'Platform Outage', '/aurionadmin/platform/outage/'),
        navSubItem('glob:platformOutage', 'Platform Tenants', '/aurionadmin/platform/tenants/'),
    ]),
];

export function getPortalItems(portals: Portal[]): PageContainerUIProps['portalItems'] {
    return objectEntries(portalItems)
        .filter(([portal]) => portals.includes(portal))
        .map(([, menu]) => menu);
}

export function removeUnauthorisedNavItems(
    session: Session,
    navItems: AurAppBarProps['navMenuItems'],
) {
    const navItemIdsToIgnore = [
        'glob:home',
        'glob:config',
        'web:admin/home',
        'my:home',
        'my:payroll',
        'my:leave',
        'my:details',
        'man:home',
        'man:leave',
        'man:availability',
        'man:people',
    ];

    return navItems.flatMap((item) => {
        if (navItemIdsToIgnore.indexOf(item.id) === -1) {
            const navSubItems = item.items?.filter((subItem) =>
                assertUserHasRequiredPermissions({
                    userPermissions: session.user?.permissions ?? {},
                    requiredPermissions: (subItem.multiPermissions as PermissionAction[]) ?? [
                        subItem.id,
                    ],
                }),
            );

            return navSubItems?.length
                ? {
                      ...item,
                      items: navSubItems,
                  }
                : [];
        }

        return item;
    });
}

function navItem(
    id: NavItem['id'],
    label: NavItem['label'],
    icon: NavItem['icon'],
    path: NavItem['path'],
    items?: NavItem['items'],
): NavItem {
    return { id, label, icon, path, items };
}

function navSubItem(
    id: NestedItem['id'],
    label: NestedItem['label'],
    path: NestedItem['path'],
    multiPermissions?: NestedItem['multiPermissions'],
): NestedItem {
    return { id, label, path, multiPermissions };
}

type NavItem = ArrayElement<PageContainerUIProps['portalItems']>;
type NestedItem = ArrayElement<Required<NavItem>['items']>;
