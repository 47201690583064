import Box from '@mui/material/Box';
import * as React from 'react';

type Props = React.PropsWithChildren<{
    sx?: React.ComponentProps<typeof Box>['sx'];
    'data-testid'?: string;
}>;

export default function AurPageBody(props: Props): JSX.Element {
    const { children, 'data-testid': dataTestId, sx = { overflow: 'auto' } } = props;

    return (
        <Box sx={{ mx: { xs: 2, sm: 3 }, my: 3, ...sx }} data-testid={dataTestId}>
            {children}
        </Box>
    );
}
