export const styles = {
    cursorDefault: {
        cursor: 'default',
    },
    fontBold: {
        fontWeight: 'bold',
    },

    toolbar: {
        justifyContent: 'center',
    },
    leftLogoContainer: {
        display: 'flex',
        userSelect: 'none',
        justifyContent: 'flex-start',
        flex: '0 1 auto',
        width: '100%',
    },
    leftLogo: {
        display: 'inline-block',
        height: '1.4rem',
        marginBottom: '-4px',
        marginTop: '-7px',
    },

    navMenusContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    navMenuItem: (isActive: boolean | undefined) => ({
        userSelect: 'none',
        color: isActive ? 'white' : 'inherit', // inherit the appbar font colour (see AurionLightTheme.components.MuiAppBar.styleOverrides.root.color)
        margin: '0 .2em',
        whiteSpace: 'nowrap',
        padding: '.5em',
        borderRadius: '.25em',
        '&:hover, &:focus': {
            background: 'grey.600',
        },
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        minWidth: 0,
    }),
    navMenuLabel: {
        marginLeft: '.5em',
        marginRight: '0',
        // Drop the text a smidge so that labels appear balanced with the icons
        marginTop: '1px',
        marginBottom: '-1px',
    },

    popupMenu: (isWidthFixed: boolean | undefined) => ({
        marginTop: '3px',
        maxHeight: '90%', // Handle content taller than viewport
        minWidth: isWidthFixed ? '500px' : undefined,
        maxWidth: isWidthFixed ? '500px' : undefined, // Fix: the menu gets wider when there's a lot of sub-links shown
    }),
    rightMenusContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 'auto',
        width: '100%',
        marginLeft: '1rem',
    },

    accountsButton: {
        minWidth: 0,
        userSelect: 'none',
        marginLeft: '.5em',
        borderRadius: '1.125rem',
        width: '2.25rem',
        height: '2.25rem',
        display: 'flex', // to center the text
        backgroundColor: 'primary.main',
        color: 'white',
        '&:hover, &:focus': {
            backgroundColor: 'secondary.main',
        },
    },

    intercomButton: {
        minWidth: 0,
        userSelect: 'none',
        marginLeft: '.5em',
        borderRadius: '1.125rem',
        width: '2.25rem',
        height: '2.25rem',
        display: 'flex', // to center the text
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid white',
        '&:hover, &:focus': {
            backgroundColor: 'secondary.main',
        },
    },
};
