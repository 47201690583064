/** @deprecated Instead use: @aurion/shared-tsoa/build/appErrors */
export default class HttpStatusError extends Error {
    public name = 'HttpStatusError';
    public status = 400;
    public context = undefined as AnyObject | undefined;

    constructor(message: string, props?: Props) {
        super(message);

        Object.assign(this, props);
        Object.setPrototypeOf(this, HttpStatusError.prototype);
    }
}

type Props = {
    status?: number;
    /** the context object is logged "as is" */
    context?: unknown;
};
