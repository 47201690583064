import pino from 'pino';
import expressPino, { HttpLogger } from 'pino-http';
import pinoToSeq from 'pino-seq';
// TODO: move this to a BE appropriate location, BE stuff shouldn't be in FE/BE shared

const loggers: Obj<pino.Logger> = {};

const loggerStream = process.env.SEQ_LOGGING_HOST
    ? pinoToSeq.createStream({ serverUrl: process.env.SEQ_LOGGING_HOST })
    : undefined;

export function getLogger(name: string, options: LoggerOptions = {}): pino.Logger {
    if (!loggers[name]) {
        const pinoOptions = {
            name,
            ...options,
            level: level(),
            base: undefined,
            mixin: () => ({ processId: process.env.PROC_ID }),
            redact: [
                'authorization',
                'headers.authorization',
                'headers.cookie',
                'headers["x-via-auth"]',
                'req.headers.authorization',
                'req.headers.cookie',
                'req.headers["x-via-auth"]',
                'err.config.headers.authorization',
                'err.config.headers["x-via-auth"]',
            ],
        };
        if (loggerStream) {
            loggers[name] = pino(pinoOptions, loggerStream);
        } else {
            loggers[name] = pino(pinoOptions);
        }
    }

    return loggers[name];
}

// TODO: move this to a BE appropriate location, BE stuff shouldn't be in FE/BE shared
export function getExpressLogger(name: string): HttpLogger {
    const logger = getLogger(name);
    return expressPino({
        logger,
        customErrorMessage: (req, _res, _error) => `request id #${req.id} has failed fatally`,
        customErrorObject: (_req, _res, _error, val) => {
            // stops http logs from having useless stack traces
            delete val.err; // eslint-disable-line no-param-reassign
            return val;
        },
    });
}

export function getAllLoggers(): typeof loggers {
    return loggers;
}

/* istanbul ignore next */
function level(): pino.LoggerOptions['level'] {
    const { SVC_LOG_LEVEL } = process.env;
    if (SVC_LOG_LEVEL && Object.keys(pino.levels.values).includes(SVC_LOG_LEVEL)) {
        return SVC_LOG_LEVEL;
    }

    if (process.env.NODE_ENV === 'test') return 'fatal'; // Don't log anything
    if (process.env.NODE_ENV === 'production') return 'info';

    return 'debug'; // development or undefined
}

type LoggerOptions = Parameters<typeof pino>[0];
