import { IconButton } from '@aurion/storybook-ui/components/IconButton';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Menu, MenuItem, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import * as React from 'react';
import { sendIntercomAction } from '../../integrations/intercom';

/**
 * Options to show Intercom help in the page, such as a product tour or link to a knowledge article.
 *
 * These options will be provided to us as they become available by our learning and support team.
 */
export type IntercomHelpOptions = {
    /**
     * Knowledge articles in Intercom have an ID that we can provide to the Intercom 'showArticle' event (see https://developers.intercom.com/installing-intercom/web/methods#intercomshowarticle-articleid).
     */
    knowledgeArticleId?: number;
    /**
     * Product tours in Intercom have an ID that we can provide to the Intercom 'startTour' event (see https://developers.intercom.com/installing-intercom/web/methods#intercomstarttour-tourid).
     */
    productTourId?: number;
};

type Props = {
    title: React.ReactNode;
};

/**
 * A map of the knowledge articles and/or product tours associated with each page in the app.
 */
const pageHelpMap: Record<string, IntercomHelpOptions> = {
    '/admin/': {
        productTourId: 82,
    },
    '/team/': {
        productTourId: 74,
    },
    '/me/': {
        productTourId: 73,
    },
    '/admin/administration/leave-types/active/': {
        knowledgeArticleId: 1951,
        productTourId: 197,
    },
    '/team/leave/applications/pending/': {
        knowledgeArticleId: 1933,
        productTourId: 196,
    },
    '/me/leave/current/': {
        knowledgeArticleId: 1932,
        productTourId: 83,
    },
    '/me/mydetails/': {
        knowledgeArticleId: 1594,
    },
};

export default function AurModuleTitleBar(props: Props): JSX.Element {
    const { title } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const openHelpMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeHelpMenu = () => {
        setAnchorEl(null);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const router = useRouter();
    const intercom = pageHelpMap[router.asPath];

    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                bgcolor: 'grey.300',
                color: 'primary.contrastText', // A "light font" for a dark background - Should be #FFFFFF 98%
                minHeight: '44px',
                zIndex: 1,
            }}
        >
            {/*
             * If title is a string, render it in Typography. Other wise it's some other type of
             * React node, so render it as is.
             */}
            {typeof title === 'string' ? (
                <Typography variant="h6" sx={{ px: { xs: 2, sm: 3 } }} data-testid="titlebar">
                    {title}
                </Typography>
            ) : (
                title
            )}
            <Stack flexDirection="row" paddingRight={3} alignItems="center">
                {/* Render the Intercom help button. */}
                <Tooltip title="Help">
                    <Box>
                        <IconButton
                            id="basic-button"
                            icon={faQuestionCircle}
                            color="light"
                            aria-controls={isOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isOpen ? 'true' : undefined}
                            onClick={openHelpMenu}
                        />
                    </Box>
                </Tooltip>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={closeHelpMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        // Product tours are disabled on mobile devices.
                        disabled={isMobile || !intercom?.productTourId}
                        onClick={() => {
                            if (intercom?.productTourId) {
                                sendIntercomAction('startTour', intercom.productTourId);
                            }
                            closeHelpMenu();
                        }}
                    >
                        Guided tour
                    </MenuItem>
                    <MenuItem
                        disabled={!intercom?.knowledgeArticleId}
                        onClick={() => {
                            if (intercom?.knowledgeArticleId) {
                                sendIntercomAction('showArticle', intercom.knowledgeArticleId);
                            }
                            closeHelpMenu();
                        }}
                    >
                        Knowledge article
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            sendIntercomAction('showSpace', 'home');
                            closeHelpMenu();
                        }}
                    >
                        General help
                    </MenuItem>
                </Menu>
            </Stack>
        </Stack>
    );
}
