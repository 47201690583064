import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { useNotify } from '../../components/util/useNotify';
import { useSession } from '../../hooks/useSession';
import {
    hasCodeOrStatus,
    is401,
    isSessionExpiredError,
    shouldResyncSessionError,
} from './useQueryHelpers';

export const STALE_TIME = 10000;

export function useLoadRequest<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    queryDetail: { queryKey: TQueryKey; queryFn: QueryFunction<TQueryFnData, TQueryKey> },
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
    const { session, updateSession } = useSession();
    const notify = useNotify();
    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
        queryDetail.queryKey,
        queryDetail.queryFn,
        {
            ...options,
            retry: retryHandler,
            onError: onErrorHandler,
        },
    );

    function onErrorHandler(error: TError) {
        if (is401(error)) {
            updateSession({ ...session, isAuthenticated: false });
        }

        if (typeof error === 'object' && 'message' in error) {
            if (isSessionExpiredError(error)) {
                updateSession({ ...session, isExpired: true });
            } else if (shouldResyncSessionError(error)) {
                updateSession({
                    ...session,
                    requiresResync: true,
                    invalidateQueriesOnResync: session.invalidateQueriesOnResync?.length
                        ? [...session.invalidateQueriesOnResync, queryDetail.queryKey]
                        : [queryDetail.queryKey],
                });
            } else {
                if (options?.onError) {
                    options?.onError?.(error);
                    return;
                }
                notify.error(error);
                return;
            }
        } else {
            notify.error('Something went wrong trying to load data.');
            return;
        }

        options?.onError?.(error);
    }

    function retryHandler(failureCount: number, error: TError) {
        const { retry = 3 } = options ?? {};

        // Unless "retry" is false, first check if the error is due to an expired session
        if (
            retry === false ||
            isSessionExpiredError(error) ||
            shouldResyncSessionError(error) ||
            is400Error(error)
        )
            return false;

        // Otherwise
        if (retry === true) return true;
        if (typeof retry === 'function') return retry(failureCount, error);
        return failureCount < retry;
    }

    return query;
}

export function is400Error(error: unknown): boolean {
    if (hasCodeOrStatus(error)) {
        const { status, code } = error;

        const statusCode = status || code || 0;

        return statusCode >= 400 && statusCode <= 499;
    }
    return false;
}
