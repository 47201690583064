import Icon from '@aurion/storybook-ui/components/Icon';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import * as React from 'react';
import { AurAppBarProps, NavItem, NestedItem } from '../AurAppBar.types';

interface AllAppsMenuItemProps {
    menuItem: NavItem;
    onNavItemClick: AurAppBarProps['onNavigate'];
    isActive: boolean;
}

function AllAppsMenuItem({
    menuItem,
    onNavItemClick,
    isActive,
}: AllAppsMenuItemProps): JSX.Element {
    return (
        <Link
            sx={{
                flexBasis: '33.333333%',
                display: 'flex',
                flexDirection: 'column',
                width: '4em',
                height: '4em',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            color={isActive ? 'primary.main' : 'inherit'}
            underline="none"
            href={menuItem.path}
            onClick={(event) => onNavItemClick(menuItem, event)}
        >
            <Icon icon={menuItem.icon} />
            <span>{menuItem.label}</span>
        </Link>
    );
}

interface AllAppsSubMenuItemProps {
    subMenuItem: NestedItem;
    onNavItemClick: AurAppBarProps['onNavigate'];
}

function AllAppsSubMenuItem({ subMenuItem, onNavItemClick }: AllAppsSubMenuItemProps): JSX.Element {
    return (
        <Link
            sx={{
                flexBasis: '100%',
                fontSize: '14px',
                lineHeight: '20px',
                margin: '0.25em 0',
            }}
            color="inherit"
            underline="none"
            href={subMenuItem.path}
            onClick={(event) => onNavItemClick(subMenuItem, event)}
        >
            <Box
                component="span"
                sx={{
                    fontSize: '12px',
                    lineHeight: '2em',
                }}
            >
                {subMenuItem.label}
            </Box>
        </Link>
    );
}

type AllAppsMenuProps = {
    navMenuItems: AurAppBarProps['navMenuItems'];
    onNavItemClick: AurAppBarProps['onNavigate'];
};

export default function AllAppsMenu(props: AllAppsMenuProps): JSX.Element {
    const { navMenuItems, onNavItemClick } = props;

    const [activeMenuItem, setActiveMenuItem] = React.useState<NavItem | null>(null);

    return (
        <Box component="div" sx={{ paddingY: '1em' }}>
            <Box
                component="div"
                sx={{ display: 'flex', flexWrap: 'wrap', padding: '1em', minWidth: '200px' }}
            >
                {navMenuItems.map((navMenuItem) => (
                    <AllAppsMenuItem
                        key={navMenuItem.id}
                        isActive={navMenuItem === activeMenuItem}
                        menuItem={navMenuItem}
                        onNavItemClick={
                            navMenuItem.items
                                ? () => setActiveMenuItem(navMenuItem)
                                : onNavItemClick
                        }
                    />
                ))}
            </Box>

            {activeMenuItem?.items && activeMenuItem.items?.length > 0 && (
                <Box
                    component="div"
                    sx={{
                        borderTop: '1px solid #D4D4D8',
                        padding: '1em',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {activeMenuItem.items.map((subMenuItem) => (
                        <AllAppsSubMenuItem
                            key={subMenuItem.id}
                            subMenuItem={subMenuItem}
                            onNavItemClick={onNavItemClick}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
}
