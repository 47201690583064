/* eslint-disable no-console */
import {
    PermissionAction,
    Portal,
} from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import AurPageBody from '@aurion/storybook-ui/components/page/AurPageBody';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSession } from '../../hooks/useSession';
import { sendIntercomAction } from '../../integrations/intercom';
import { useAccessControl } from '../../lib/auth/permissions/useAccessControl';
import { loadSessionState } from '../../lib/auth/session';
import { Session } from '../../lib/auth/session.types';
import { Query, useQueryHelpers } from '../../lib/requests/useQueryHelpers';
import useTenant from '../../lib/useTenant';
import AurModuleTitleBar from './AurModuleTitleBar';
import ConfirmationDialog from './dialog/ConfirmationDialog';
import {
    getPortalItems,
    navMenuItemsAdmin,
    navMenuItemsGlobal,
    navMenuItemsHome,
    navMenuItemsManager,
    navMenuItemsMe,
    removeUnauthorisedNavItems,
} from './PageContainer.menu';
import { formatUpdatedPermissionsInfo, diffZippedWebPermissions } from './PageContainer.utils';
import { PageContainerUIProps } from './PageContainerUI';
import PageContainerUI from './PageContainerUI/PageContainerUI';

const navMenuItemsMap: { [K in NavView]: PageContainerUIProps['navMenuItems'] } = {
    home: navMenuItemsHome,
    me: navMenuItemsMe,
    team: navMenuItemsManager,
    payadmin: navMenuItemsAdmin,
    configadmin: navMenuItemsGlobal,
};

const titleSeparator = ' — '; // em-dash
const pathSeparator = ' | ';

const skipMfaCheck = !!process.env.NEXT_PUBLIC_auth_mfa_disabled;
const mfaPortals: Portal[] = ['admin'];

/**
 * PageContainer centralises the generation of PageContainerUI properties.
 */
export default function PageContainer(props: PageContainerProps): JSX.Element {
    const {
        children,
        navView,
        title,
        headTitle,
        moduleTitle,
        isPublic,
        portal,
        loading,
        skipBodyWrap = false,
        mfaRequired,
        requiredPermissions,
    } = props;

    const { invalidateQueryData } = useQueryHelpers();
    const { session, updateSession } = useSession();
    const { givenName, familyName, isMfaLogin } = session.user || {};
    const { tenantName, tenantLogoURL } = useTenant();
    const usernames = [givenName, familyName];
    const router = useRouter();
    const { hasPermission } = useAccessControl();
    const [permissionChangedModal, setPermissionChangedModal] = useState<{
        isOpen: boolean;
        applySession: Session;
        permissionChanges: [string[], string[]];
        invalidateQueriesOnApply?: QueryKey[];
    }>({ isOpen: false, applySession: session, permissionChanges: [[], []] });

    // Permission Change: 1. On requiresResync = true => Display Modal
    useEffect(() => {
        const resyncSession = async () => {
            const newSession = await loadSessionState();

            const [added, removed] = diffZippedWebPermissions(
                session.user?.permissions || {},
                newSession.user?.permissions || {},
            );
            const [formattedAddedInfo, formattedRemovedInfo] = formatUpdatedPermissionsInfo([
                added,
                removed,
            ]);

            setPermissionChangedModal({
                isOpen: true,
                permissionChanges: [formattedAddedInfo, formattedRemovedInfo],
                invalidateQueriesOnApply: session.invalidateQueriesOnResync,
                applySession: newSession,
            });
        };

        if (session.requiresResync) {
            resyncSession();
        }

        // If the session is expired (employment ended, etc), redirect to login.
        if (session.isExpired) {
            router.push({ pathname: '/login' });
        }
    }, [session]);

    // Permission Change: 2. On modal button clicked => apply changes, and close modal
    const applyNewSession = () => {
        // Check if we have any queries to invalidate then process them.
        if (permissionChangedModal.invalidateQueriesOnApply?.length) {
            const queries: Query<unknown>[] = permissionChangedModal.invalidateQueriesOnApply.map(
                (queryKey) => ({
                    queryKey,
                    queryFn: async () => undefined,
                }),
            );
            invalidateQueryData(...queries);
        }
        updateSession(permissionChangedModal.applySession);
        setPermissionChangedModal({ ...permissionChangedModal, isOpen: false });
    };

    // Check - Need to be logged in
    if (!(isPublic || session.isAuthenticated)) {
        router.push({ pathname: '/login', query: { from: router.asPath } });
        return <></>;
    }

    // Check - Need MFA enabled
    if (!skipMfaCheck) {
        const checkMfa = mfaRequired ?? (portal && mfaPortals.includes(portal));
        if (checkMfa && !isMfaLogin) {
            router.push({ pathname: '/admin/mfa-required' });
            return <></>;
        }
    }

    // Check - has permission to view page
    const canView = requiredPermissions ? hasPermission(requiredPermissions) : true;

    if (!canView) {
        router.replace('/');
        return <></>;
    }

    const derivedHeadTitle = headTitle
        ? `${headTitle}${titleSeparator}${tenantName} Aurion`
        : Array.isArray(title)
        ? `${title.join(pathSeparator)}${titleSeparator}${tenantName} Aurion`
        : title
        ? `${title}${titleSeparator}${tenantName} Aurion`
        : ''; // Reset to blank of the caller hasn't provided a title

    const onNavigate: PageContainerUIProps['onNavigate'] = (navItem, event) => {
        if (navItem.path) {
            router.push(navItem.path);
        } else {
            console.log(`Unhandled click: "${navItem.label}"`);
        }

        event?.preventDefault(); // Stop a link from navigating away
        event?.stopPropagation(); // stop the menu from closing
    };

    const onLogout: PageContainerUIProps['onLogout'] = (event) => {
        sendIntercomAction('shutdown');
        router.push('/logout');
        event?.preventDefault(); // Stop a link from navigating away
        event?.stopPropagation(); // stop the menu from closing
    };

    const portalItems = getPortalItems(session.user?.portals ?? []);

    const currentPage = `${router.pathname}/`;
    let currentPortal = '';

    portalItems.forEach((navItem) => {
        if (navItem.path && currentPage.startsWith(navItem.path)) {
            currentPortal = navItem.path;
        }
    });

    const navMenuItems = navView
        ? removeUnauthorisedNavItems(session, navMenuItemsMap[navView])
        : [];

    return (
        <PageContainerUI
            loading={loading}
            onNavigate={onNavigate}
            onLogout={onLogout}
            currentPage={currentPage}
            currentPortal={currentPortal}
            portalItems={portalItems}
            logo="/mock/logo.svg"
            tenantLogo={tenantLogoURL}
            tenantName={tenantName}
            navMenuItems={navMenuItems}
            usernames={usernames}
            isAuthenticated={session.isAuthenticated}
        >
            <Head>
                <title>{derivedHeadTitle}</title>
            </Head>

            {getModuleTitle({ moduleTitle, title })}
            {!skipBodyWrap ? <AurPageBody>{children}</AurPageBody> : children}

            <ConfirmationDialog
                title="Your permissions have been updated"
                message={
                    <InformativePermissionChangeMsg
                        changes={permissionChangedModal.permissionChanges}
                    />
                }
                confirm={applyNewSession}
                confirmText="Continue"
                open={permissionChangedModal.isOpen}
            />
        </PageContainerUI>
    );
}

const InformativePermissionChangeMsg = (props: { changes: [string[], string[]] }) => {
    const { changes } = props;
    const [added, removed] = changes;

    return (
        <Stack spacing={2}>
            {!!added.length && (
                <Stack>
                    <Typography>You now have access to:</Typography>
                    <List disablePadding dense>
                        {added.map((addedPerm) => (
                            <ListItem key={addedPerm}>{addedPerm}</ListItem>
                        ))}
                    </List>
                </Stack>
            )}
            {!!removed.length && (
                <Stack>
                    <Typography>You no longer have access to: </Typography>
                    <List disablePadding dense>
                        {removed.map((removedPerm) => (
                            <ListItem key={removedPerm}>{removedPerm}</ListItem>
                        ))}
                    </List>
                </Stack>
            )}
            {!added.length && !removed.length && (
                <Typography>Please click continue to apply new permissions</Typography>
            )}
        </Stack>
    );
};

/**
 * Convert moduleTitle (or title) into a AurModuleTitleBar (if possible).
 */
function getModuleTitle(
    props: Pick<PageContainerProps, 'moduleTitle' | 'title'>,
): PageContainerProps['moduleTitle'] {
    const { moduleTitle, title } = props;

    // Caller does not want a titlebar rendered
    if (moduleTitle === false) {
        return undefined;
    }
    // Wrap the moduleTitle in the standard AurModuleTitleBar
    if (moduleTitle) {
        return <AurModuleTitleBar title={moduleTitle} />;
    }

    // Fallback/default to using the page title as the moduleTitle.
    return title ? (
        <AurModuleTitleBar title={Array.isArray(title) ? title[0] : title} />
    ) : undefined;
}

export type NavView = 'home' | 'me' | 'team' | 'payadmin' | 'configadmin';

export type PageContainerProps = PropsWithChildren<{
    title?: string | string[];
    headTitle?: string;
    /**
     * The module title bar to render.
     *
     * If a string, it will be rendered by AurModuleTitleBar.
     *
     * If false, no title bar will be rendered.
     */
    moduleTitle?: false | string | JSX.Element;
    isPublic?: true; // Set to true if the user does not need to be logged into see this page.
    portal?: Portal; // The permissionGroup (portal) type the user needs to see this page.  Do not mix with isPublic=true
    navView?: NavView; // Which nav structure to render.
    loading?: boolean;
    skipBodyWrap?: boolean;
    mfaRequired?: boolean; // Undefined = auto = true if is an Administrator portal
    requiredPermissions?: PermissionAction | PermissionAction[]; // the user must have at least one of the provided permissions
}>;
