import elId from '@aurion/storybook-ui/lib/elId';
import MuiDrawer from '@mui/material/Drawer';
import * as React from 'react';

export default function Drawer(props: Props): Returns {
    const {
        children,
        drawOpen,
        handleDrawerToggle,
        // window,
    } = props;

    const container = document.body; // window !== undefined
    // ? () => window().document.body
    // : undefined;

    return (
        <MuiDrawer
            // A temporary draw is rendered to container (in this case the document root)
            // so this can go anywhere
            {...elId({ id: 'allApps-sidebar--draw' })}
            container={container}
            variant="temporary"
            anchor="right"
            open={drawOpen}
            onClose={handleDrawerToggle}
            sx={{
                width: '80%',
                bottom: 0,
                height: 'auto',
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            {children}
        </MuiDrawer>
    );
}

export type DrawerProps = Props;

type Props = React.PropsWithChildren<{
    drawOpen: boolean;
    handleDrawerToggle: React.ComponentProps<typeof MuiDrawer>['onClose'];
}>;
type Returns = ReturnType<React.FunctionComponent>;
