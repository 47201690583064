import { LinearProgress } from '@mui/material';
import React from 'react';
import elId from '../lib/elId';

export type LoadingProps = {
    tid?: string;
};

export default function Loading({ tid }: LoadingProps): JSX.Element {
    const prefix = tid && `${tid}-`;
    const testId = [prefix, 'loading-progress'].join('');

    return (
        <div
            style={{
                width: '100%',
                marginBottom: '-4px', // Prevent the page from jumping down/up when shown/hidden.
            }}
            {...elId({ id: testId })}
        >
            <LinearProgress color="primary" />
        </div>
    );
}
