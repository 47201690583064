import { getFriendlyErrorMessage } from '@aurion/shared-functions/build/error/errorUtils';
import { AppError, isAppError } from '@aurion/shared-tsoa/build/appError';
import { IconButton } from '@aurion/storybook-ui/components/IconButton';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { first, last } from 'lodash';
import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import React from 'react';

export type NotifyHook = {
    success: (message: string) => void;
    info: (message: string) => void;
    warning: (message: string) => void;
    error: (messageOrError?: unknown, customMessage?: string) => void;
};

export function useNotify(): NotifyHook {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const notify = (message: SnackbarMessage, variant: VariantType) => {
        enqueueSnackbar(message, {
            id: 'snackbar-main',
            variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            style: { whiteSpace: 'pre-line' },
            action: (snackBarKey) => (
                <IconButton
                    onClick={() => closeSnackbar(snackBarKey)}
                    data-testid="snackbar-close-button"
                    icon={faX}
                    size="sm"
                    color="light"
                />
            ),
        });
    };

    return {
        success: (message) => notify(message, 'success'),
        info: (message) => notify(message, 'info'),
        warning: (message) => notify(message, 'warning'),
        error: (messageOrError, customMessage) =>
            notify(getErrorSnackbarMessage(messageOrError, customMessage), 'error'),
    };
}

function getErrorSnackbarMessage(messageOrError: unknown, customMessage?: string): SnackbarMessage {
    const { appError } = messageOrError as { appError?: AppError };

    if (isAppError(appError)) {
        const errorsMessages = collectAppErrorMessages(appError);

        const firstError = first(errorsMessages);
        const lastError = last(errorsMessages);

        const message =
            firstError === lastError || !lastError
                ? firstError
                : `${firstError} because ${lastError}`;

        return getFriendlyErrorMessage(message, customMessage);
    }

    return getFriendlyErrorMessage(messageOrError, customMessage);
}

function collectAppErrorMessages(appError: AppError): string[] {
    const errorMessages: string[] = [];

    let nextError = appError as Maybe<AppError>;

    while (nextError) {
        const { originalError, message } = nextError;

        if (message) errorMessages.push(message);

        nextError = isAppError(originalError) ? originalError : undefined;
    }
    return errorMessages;
}
