/* istanbul ignore file */ (''); // eslint-disable-line lines-around-directive

/**
 * Forcibly typecast
 */
export function cast<T>(x: unknown): T {
    return x as unknown as T;
}

/**
 * Forcibly typecast the destination object, but check the applied arguments match the destination object.
 */
export function assignAs<T>(obj: T, ...sources: Partial<T>[]): T {
    return Object.assign(obj as unknown as AnyObject, ...sources);
}

/**
 * Check that the provided object/value matches the typescript type.
 *
 * Example:
 * ```
 * // before:
 * const myObject: SomeType = { … };
 * untypedFunction(myObject);
 *
 * // after
 * untypedFunction(as<SomeType>({ … }));
 * ```
 */
export function as<T>(x: T): T {
    return x;
}

/**
 * Filter out undefined & null without losing type info.
 *
 * Example:
 * ```
 * [{ my: 'object' } | undefined]
 *     .filter(isNotUndefined)
 *     .map((obj) => obj.my);
 * ```
 */
export function isNotUndefined<T>(x: T): x is Exclude<T, undefined | null | void> {
    return x !== undefined && x !== null;
}

export async function parseJson<T = unknown>(json: string): P<T> {
    return JSON.parse(json);
}

/**
 * Because Object.entries() poorly typed.
 */
export function objectEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
    return Object.entries(obj) as [keyof T, T[keyof T]][];
}

/**
 * Is the property one of these things.
 *
 * 1) instead of `x === 'a' || x === 'b' || x === 'c', or
 * 2) `['status1', 'status5'].includes(a.status)` which is poorly typed.
 *
 * @example
 * if (isOneOf(thing.status, ['option1', 'option2', 'option5']) {
 *    //
 * }
 */
export function isOneOf<T, U extends T = T>(typedThing: T, allowedThings: U[]): boolean {
    return allowedThings.includes(typedThing as U);
}

/**
 * Because Object.keys() poorly typed.
 */
export function objectKeys<T extends object>(obj: T): (keyof T)[] {
    return Object.keys(obj) as (keyof T)[];
}
