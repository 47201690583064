import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import Icon, { IconColors, IconSizes } from './Icon';

// The Icon buttons size will be 1.5x larger than the icon size
const sizeToUnits: Record<IconSizes, string> = {
    sm: '24px', // 16px * 1.5
    md: '36px', // 24px * 1.5
    lg: '48px', // 32px * 1.5
    xl: '60px', // 40px * 1.5
};

export type IconButtonProps = Omit<MuiIconButtonProps, 'size' | 'color'> & {
    icon: IconDefinition;
    size?: IconSizes;
    color?: IconColors;
};

export const IconButton = ({ icon, size = 'md', color, ...props }: IconButtonProps) => (
    <MuiIconButton
        sx={{
            height: sizeToUnits[size],
            width: sizeToUnits[size],
        }}
        {...props}
    >
        <Icon icon={icon} size={size} color={color} />
    </MuiIconButton>
);
