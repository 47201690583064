import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import AllAppsMenu from './AllAppsMenu/AllAppsMenu';
import AppBar from './AppBar/AppBar';
import { AurAppBarProps } from './AurAppBar.types';
import Drawer from './Drawer/Drawer';

export default function AurAppBar(props: AurAppBarProps): JSX.Element {
    const isXs = useMediaQuery(useTheme().breakpoints.only('xs'));

    const {
        logo,
        tenantLogo,
        tenantName,
        navMenuItems,
        portalItems,
        currentPage,
        currentPortal,
        onLogout,
        onNavigate,
        usernames,
        isAuthenticated,
        appBarRef,
    } = props;

    const [drawOpen, setDrawOpen] = useState(false);

    function handleDrawerToggle() {
        setDrawOpen(!drawOpen);
    }

    return (
        <>
            <AppBar
                onShowAppsSidebar={handleDrawerToggle}
                onNavItemClick={onNavigate}
                usernames={usernames}
                currentPage={currentPage}
                currentPortal={currentPortal}
                logo={logo}
                tenantLogo={tenantLogo}
                tenantName={tenantName}
                onLogout={onLogout}
                navMenuItems={navMenuItems}
                portalItems={portalItems}
                isAuthenticated={isAuthenticated}
                appBarRef={appBarRef}
            />
            <Drawer {...{ drawOpen: isXs && drawOpen, handleDrawerToggle }}>
                <AllAppsMenu navMenuItems={navMenuItems} onNavItemClick={onNavigate} />
            </Drawer>
        </>
    );
}
