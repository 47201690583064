import { EmployeePersonalDetails } from '@aurion/service-organisation/employee/employee.types';
import { toDisplayCleanFixed } from './numberUtil';

export function joinString(joinSymbol: string, ...args: string[]): string {
    return args.join(joinSymbol);
}

export function capitalizeFirstLetter(text?: string): string {
    return text ? text.charAt(0).toUpperCase() + text.toLowerCase().slice(1) : '';
}

export function getFullName(firstName: string, lastName: string): string {
    return [firstName, lastName]
        .filter((word) => word)
        .map((word) => capitalizeFirstLetter(word))
        .join(' ');
}

export function getPersonFullName(
    person: Pick<EmployeePersonalDetails, 'givenName' | 'familyName'>,
): string {
    return getFullName(person?.givenName || '', person?.familyName || '');
}

export function getInitials(usernames: Maybe<string>[]): string {
    const names = usernames.filter(Boolean) as string[]; // Strip out blanks/undefined

    if (!names.length) return '?';

    if (names.length === 1) {
        return names[0].substr(0, 2).toUpperCase();
    }

    return [names[0].substr(0, 1), names[names.length - 1].substr(0, 1)].join('').toUpperCase();
}

export const capitalizeAllWords = (text: string): string =>
    text
        .split(' ')
        .filter((word) => word)
        .map((word) => capitalizeFirstLetter(word))
        .join(' ');

export function pluraliseWithUnits(
    value: number,
    unitSingularName: string,
    precisionDigits = 0,
): string {
    if (precisionDigits) {
        const fixedValue = toDisplayCleanFixed(value, precisionDigits);
        return `${fixedValue} ${pluralise(unitSingularName, Number(fixedValue))}`;
    }
    return `${value} ${pluralise(unitSingularName, value)}`;
}

export function pluralise(word: string, value: number, plural?: string): string {
    return value === 1 || value === -1 ? word : plural ?? `${word}s`;
}

export const splitWordsByCase = (text: string): string => text.replace(/([a-z])([A-Z])/g, '$1 $2');

export const stripAllSpaces = (text: string): string => text.replace(/ /g, '');
